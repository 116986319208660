<template>
  <div class="user page">
    <Nav></Nav>
    <main class="wrap">
      <div class="content">
        <div class="title">运单号：{{ orderNo }}</div>
        <div class="list">
          <div class="item" v-for="(item, index) in list" :key="index">
            <div class="head">
              <div style="width: 60%;"> {{ item.postCode }}</div>
              <div>
                <a-button type="primary" @click="down(item.filePath, item.postCode);">下载</a-button>
                <a-button type="primary" @click="print(item.filePath);" class="btnmar">打印</a-button>
              </div>
            </div>
            <div>
              <img :src="item.filePath" alt="" class="cover" />
            </div>
          </div>
        </div>
      </div>
    </main>
    <Foot></Foot>
  </div>
</template>
<script>


import Foot from "@/components/foot";
import Nav from "@/components/Nav.vue";

export default {
  components: {
    Nav,
    Foot,
  },
  data() {
    return {
      order: {},
      orderNo: "",
      list: [],
    };
  },
  computed: {},
  created() {
    if (this.$route.name === "Home") this.main_swiper = true;
    let geturl = window.location.href;
    let getqyinfo = geturl.split("?")[1];
    let getqys = getqyinfo.split("&");
    let obj = {}; //创建空对象，接收截取的参数
    for (let i = 0; i < getqys.length; i++) {
      let item = getqys[i].split("=");
      let key = item[0];
      let value = item[1];
      obj[key] = value;
    }
    this.orderNo = obj.id;
    this.loadData();
  },
  mounted() {

  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    $route: "fetchDate",
  },
  methods: {
    fetchDate() {
      if (this.$route.name !== "Home") {
        this.main_swiper = false;
      } else {
        this.main_swiper = true;
      }
    },
    loadData() {
      this.api
        .getwaybillbarCode({
          orderNo: this.orderNo,
        })
        .then((res) => {
          this.list = res;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    down(filePath, file_name) {
      //下载图片地址和图片名
      var image = new Image();
      // 解决跨域 Canvas 污染问题
      image.src = filePath + "?v=" + Math.random(); // 处理缓存
      image.crossOrigin = "*"; // 支持跨域图片
      image.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = file_name || "img"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
    },
    print(filePath) {
      const printHTML = '<img id="image" style="width:500px;height:200px;" src="' + filePath + '" />';
      // 将打印的区域赋值给新窗口body，进行打印
      const newWindow = window.open('', '');
      newWindow.document.write(printHTML);

      const image = newWindow.document.getElementById('image').cloneNode();
      image.addEventListener('load', function () {
        newWindow.window.print();//调用新窗口的打印
        newWindow.window.close();
      });
    }
  },
};
</script>


<style lang="less">
@import "~ant-design-vue/dist/antd.less";

.content {
  padding-top: 30px;
  display: flex;
  ;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}

.title {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-weight: 700;
}

.list {
  width: 600px;

  .item {
    padding-bottom: 20px;

    .head {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      height: 40px;
      line-height: 40px;
    }
  }
}

.btnmar{
  margin-left:10px;
}
</style>